@use 'assets/scss/global' as *;

.container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  @media screen and (max-width: $mobilePortraitWidth) {
    width: 100%;
  }

  &.submitButton {
    width: 38rem;
  }
}

.inputContainer {
  @media screen and (max-width: $mobilePortraitWidth) {
    width: 100%;
    margin-bottom: 0;
  }
}

.input {
  width: 37.4rem;
  @media screen and (max-width: $mobilePortraitWidth) {
    width: 100%;
  }
}


