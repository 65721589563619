@use 'assets/scss/global' as *;

$px-445: 445px;
.promoCodeLink {
  padding: 0;
  margin-bottom: 1.6rem;
  text-decoration: underline;
}

.promoCodeGroup {
  width: 44rem;
  margin-top: 2rem;
  display: inline-block;

  .promoCodeInputText {
    min-height: 4rem;
    margin-top: 0.4rem;

    display: flex;
    flex-direction: row;
    justify-content: flex-start;

    .promoCodeInputContainer {
      margin: 0 1.2rem 0 0;
    }

    .promoCodeInputBox {
      display: flex;
      flex-direction: row;
      width: 24.2rem;
      text-transform: uppercase;

      &.valid {
        background: #E7FDF1;
        border: 0.2rem solid #008536;
        position: relative;
        padding-right: 2.4rem; /* Adjust padding to make space for the icon */
      }

      &.invalid {
        background: #FFE6E0;
        border: 0.2rem solid #FF221E;
      }

      @media screen and (max-width: $mobilePortraitWidth) {
        min-width: 0;
        width: 100%;
      }
    }

    .promoCodeInputTickIcon {
      color: #008536;
      width: 1.8rem;
      height: 1.8rem;
    }

    .promoCodeInputErrorIcon {
      /* Rectangle 292 */
      color: #CC0000;
      width: 1.8rem;
      height: 1.8rem;
    }

    .promoCodeApplyButton {
      width: 12rem;
      height: 4rem;
      background: #E5E5E5;
      color: black;

      .promoCodeApplyButtonText {
        font-weight: 500;
        margin: 0;
      }
    }
  }
  @media screen and (max-width: $mobilePortraitWidth) {
    width: 100%;
    .promoCodeInputText {
      .promoCodeInputContainer {
        flex: 1;
      }
      @media screen and (max-width: $px-445) {
        .promoCodeApplyButton {
          min-width: 8rem;
        }
      }
    }
  }
}
